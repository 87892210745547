
//公共库
import { Component, Vue } from "vue-property-decorator";

//本地引入
import Pagination from "@/comp/Pagination/index.vue";
import { delBackpack, getBackpackList, getShopPropmodelconfList } from "@/api/request/item";
import GFunc from "@/utils/gFunc";

//组件
@Component({
  name: "BackPack",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private listLoading: boolean = true;

  //请求参数
  private listQuery: any = {
    //页码数据
    page: 1,
    row: 20,

    //临时数据
    userid_str: "",
    prop_id_str: "",

    //上传数据
    prop_id: 0,
    userid: 0,
    nickname: "",
  };

  //创建
  created() {
    //获取列表
    this.getList();
  }

  //获取数据
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.userid = Number(this.listQuery.userid_str);
    this.listQuery.prop_id = Number(this.listQuery.prop_id_str);

    //获取数据
    const { data } = await getBackpackList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理重置
  private handleReset(): void {
    //清空数据
    this.listQuery.page = 1;
    (this.listQuery.prop_id = 0), (this.listQuery.nickname = "");
    this.listQuery.userid_str = "";
    (this.listQuery.prop_id_str = ""),
      //获取数据
      this.getList();
  }

  //查询
  private handleFilter(): void {
    //清空数据
    this.listQuery.page = 1;

    //获取数据
    this.getList();
  }

  //处理删除
  private handleDel(id: any): void {
    //数据赋值
    this.currentId = id;

    //显示对话捐给
    this.numDialog = true;
  }

  //-------------------------------------- 删除对话框 --------------------------------------
  //定义变量
  private delCount: number = 1;
  private currentId: number = -1;
  private numDialog: boolean = false;

  //关闭数量
  private numCancel(): void {
    //清空数据
    this.delCount = 1;
    this.currentId = -1;

    //隐藏都画框
    this.numDialog = false;
  }

  //数量确定
  private numConfirm(): void {
    //显示提示
    this.$confirm(`您确定要删除${this.delCount}个ID为${this.currentId}的背包 ？`, "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //请求数据
        await delBackpack({ id: this.currentId, num: this.delCount });

        //隐藏对话框
        this.numDialog = false;
        this.currentId = -1;
        this.delCount = 1;

        //获取列表
        this.getList();

        //显示提示
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }
}
