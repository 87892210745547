import { render, staticRenderFns } from "./back_pack.vue?vue&type=template&id=bfd02298&scoped=true"
import script from "./back_pack.vue?vue&type=script&lang=ts"
export * from "./back_pack.vue?vue&type=script&lang=ts"
import style0 from "./back_pack.vue?vue&type=style&index=0&id=bfd02298&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfd02298",
  null
  
)

export default component.exports